import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/list/style";
import _List from "vant/es/list";
import "core-js/modules/es.array.push.js";
import { activateRateDetail, activateRateMonthDetail } from '@/api/wxwork';
import { activationDetail, notActivationDetail, provinceFind, cityFind, areaFind } from '@/api/wxproject';
import Selector from '@/components/Selector';
import dayjs from 'dayjs';
export default {
  components: {
    [_List.name]: _List,
    [_Tab.name]: _Tab,
    [_Tabs.name]: _Tabs,
    [_Icon.name]: _Icon,
    [_Search.name]: _Search,
    [_Empty.name]: _Empty,
    Selector
  },
  data() {
    return {
      loading: false,
      finished: false,
      loading1: false,
      finished1: false,
      isfirst: false,
      pageNumber: 0,
      pageNumber1: 0,
      searchname: '',
      activeName: 1,
      provinceList1: [],
      provinceList2: [],
      cityList1: [],
      cityList2: [],
      areaList1: [],
      areaList2: [],
      typeList: [{
        label: '不限',
        value: ''
      }, {
        label: '已取消',
        value: 'CANCELLED'
      }, {
        label: '正常',
        value: 'ACTIVATED'
      }],
      typesDate: [{
        label: '不限',
        value: ''
      }, {
        label: '新增',
        value: 'NEW'
      }, {
        label: '正常',
        value: 'NOMAL'
      }],
      types: '',
      positionList: [],
      status: '',
      province1: '',
      province2: '',
      city1: '',
      city2: '',
      area1: '',
      area2: '',
      listData: [],
      listData1: [],
      listDatacnt: '-',
      listDatacnt1: '-'
    };
  },
  mounted() {
    document.title = '激活详情';
    if (this.$route.query.activeName && this.$route.query.activeName == 2) {
      this.getData1();
    }

    // this.getData1();
    this.getData2();
    this.getProvince();
  },
  methods: {
    goback() {
      this.$router.push({
        path: '/realtimedynamicDetails',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type
        }
      });
      // window.history.back()
    },

    getData1() {
      this.pageNumber = 0;
      activationDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: this.pageNumber++,
        pageSize: 20,
        status: this.status,
        province: this.province1,
        city: this.city1,
        district: this.area1,
        type: this.types
      }).then(res => {
        this.listDatacnt = res.data.total;
        this.listData = res.data.data;
      });
    },
    getData2() {
      this.pageNumber1 = 0;
      notActivationDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: this.pageNumber1++,
        pageSize: 20,
        status: this.status,
        province: this.province2,
        city: this.city2,
        district: this.area2,
        type: this.types
      }).then(res => {
        this.listDatacnt1 = res.data.total;
        this.listData1 = res.data.data;
      });
    },
    getProvince() {
      provinceFind().then(res => {
        this.provinceList1 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList1.unshift({
          label: '不限',
          value: ''
        });
        if (this.$route.query.province && this.$route.query.activeName == 1) {
          this.province1 = this.$route.query.province;
          this.areaList1 = [];
          this.areaList1.unshift({
            label: '不限',
            value: ''
          });
          cityFind({
            provinceCode: this.province1
          }).then(res => {
            this.cityList1 = res.data.data.map(item => {
              return {
                label: item.name,
                value: item.code
              };
            });
            this.cityList1.unshift({
              label: '不限',
              value: ''
            });
            if (this.$route.query.city) {
              this.city1 = this.$route.query.city;
              areaFind({
                cityCode: this.city1
              }).then(res => {
                this.areaList1 = res.data.data.map(item => {
                  return {
                    label: item.name,
                    value: item.code
                  };
                });
                this.areaList1.unshift({
                  label: '不限',
                  value: ''
                });
                if (this.$route.query.area) {
                  this.area1 = this.$route.query.area;
                }
              });
            }
          });
        }
        this.provinceList2 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.provinceList2.unshift({
          label: '不限',
          value: ''
        });
        if (this.$route.query.province && this.$route.query.activeName == 2) {
          this.province2 = this.$route.query.province;
          this.areaList2 = [];
          this.areaList2.unshift({
            label: '不限',
            value: ''
          });
          cityFind({
            provinceCode: this.province2
          }).then(res => {
            this.cityList2 = res.data.data.map(item => {
              return {
                label: item.name,
                value: item.code
              };
            });
            this.cityList2.unshift({
              label: '不限',
              value: ''
            });
            if (this.$route.query.city) {
              this.city2 = this.$route.query.city;
              areaFind({
                cityCode: this.city2
              }).then(res => {
                this.areaList2 = res.data.data.map(item => {
                  return {
                    label: item.name,
                    value: item.code
                  };
                });
                this.areaList2.unshift({
                  label: '不限',
                  value: ''
                });
                if (this.$route.query.area) {
                  this.area2 = this.$route.query.area;
                }
              });
            }
          });
        }
      });
    },
    getCity1() {
      console.log(this.province1);
      this.areaList1 = [];
      this.areaList1.unshift({
        label: '不限',
        value: ''
      });
      this.area1 = '';
      // this.cityList = [];
      cityFind({
        provinceCode: this.province1
      }).then(res => {
        this.cityList1 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList1.unshift({
          label: '不限',
          value: ''
        });
        this.city1 = '';
        this.getData1();
      });
    },
    getCity2() {
      console.log(this.province2);
      this.areaList2 = [];
      this.areaList2.unshift({
        label: '不限',
        value: ''
      });
      this.area2 = '';
      // this.cityList = [];
      cityFind({
        provinceCode: this.province2
      }).then(res => {
        this.cityList2 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.cityList2.unshift({
          label: '不限',
          value: ''
        });
        this.city2 = '';
        this.getData2();
      });
    },
    getArea1() {
      // this.areaList = [];
      areaFind({
        cityCode: this.city1
      }).then(res => {
        this.areaList1 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.areaList1.unshift({
          label: '不限',
          value: ''
        });
        this.area1 = '';
        this.getData1();
      });
    },
    getArea2() {
      // this.areaList = [];
      areaFind({
        cityCode: this.city2
      }).then(res => {
        this.areaList2 = res.data.data.map(item => {
          return {
            label: item.name,
            value: item.code
          };
        });
        this.areaList2.unshift({
          label: '不限',
          value: ''
        });
        this.area2 = '';
        this.getData2();
      });
    },
    getData() {
      console.log(1234);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      setTimeout(() => {
        if (this.$route.query.activeName && !this.isfirst) {
          this.activeName = this.$route.query.activeName;
        }
        console.log('初始化', this.activeName);
        if (this.activeName === '1') {
          if (this.listData.length >= this.listDatacnt) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          if (this.$route.query.activeName && !this.isfirst) {
            console.log('初始化2', this.$route.query.status);
            // this.activeName = this.$route.query.activeName
            if (this.$route.query.status) {
              this.status = this.$route.query.status;
            } else {
              this.status = '';
            }
            if (this.$route.query.types) {
              this.types = this.$route.query.types;
            } else {
              this.types = '';
            }
            if (this.$route.query.province) {
              this.province1 = this.$route.query.province;
            } else {
              console.log('初始化3', this.$route.query.status);
              this.province1 = '';
            }
            if (this.$route.query.city) {
              this.city1 = this.$route.query.city;
            } else {
              this.city1 = '';
            }
            if (this.$route.query.area) {
              this.area1 = this.$route.query.area;
            } else {
              this.area1 = '';
            }
            this.isfirst = true;
          } else {
            this.status = '', this.types = '', this.province1 = '', this.city1 = '', this.area1 = '';
          }
          this.pageNumber = 0;
          this.getData1();
        } else {
          if (this.listData1.length >= this.listDatacnt1) {
            this.finished1 = true;
          } else {
            this.finished1 = false;
          }
          if (this.$route.query.activeName && !this.isfirst) {
            console.log('初始化2', this.$route.query.status);
            // this.activeName = this.$route.query.activeName
            if (this.$route.query.status) {
              this.status = this.$route.query.status;
            } else {
              this.status = '';
            }
            if (this.$route.query.types) {
              this.types = this.$route.query.types;
            } else {
              this.types = '';
            }
            if (this.$route.query.province) {
              this.province2 = this.$route.query.province;
            } else {
              this.province2 = '';
            }
            if (this.$route.query.city) {
              this.city2 = this.$route.query.city;
            } else {
              this.city2 = '';
            }
            if (this.$route.query.area) {
              this.area2 = this.$route.query.area;
            } else {
              this.area2 = '';
            }
            this.isfirst = true;
          } else {
            this.status = '', this.province2 = '', this.city2 = '', this.area2 = '';
            this.types = '';
          }
          this.pageNumber1 = 0, this.getData2();
        }
      }, 200);
    },
    onLoad() {
      console.log(2222);
      if (this.listDatacnt === -1) {
        return;
      }
      activationDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: this.pageNumber++,
        pageSize: 10,
        status: this.status,
        province: this.province1,
        city: this.city1,
        district: this.area1,
        type: this.types
      }).then(res => {
        let list = this.listData;
        res.data.data.map(res1 => {
          list.push(res1);
        });
        this.listData = list;
        this.listDatacnt = res.data.total;
        this.loading = false;
        if (this.listData.length >= res.data.total) {
          this.finished = true;
        }
      });
    },
    onLoad1() {
      notActivationDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        pageNumber: this.pageNumber1++,
        pageSize: 10,
        status: this.status,
        province: this.province2,
        city: this.city2,
        district: this.area2,
        type: this.types
      }).then(res => {
        let list = this.listData1;
        res.data.data.map(res1 => {
          list.push(res1);
        });
        this.listData1 = list;
        this.listDatacnt1 = res.data.total;
        this.loading1 = false;
        if (this.listData1.length >= res.data.total) {
          this.finished1 = true;
        }
      });
    },
    goPlanDetail(orgTerminalCode, type1, status, planCode) {
      this.$router.push({
        path: '/TerminalPlanDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type,
          planCode: planCode,
          type1: type1,
          status1: status,
          orgTerminalCode: orgTerminalCode,
          ActivationDetail: true,
          activeName: this.activeName,
          status: this.status,
          types: this.types,
          province: this.activeName == 1 ? this.province1 : this.province2,
          city: this.activeName == 1 ? this.city1 : this.city2,
          area: this.activeName == 1 ? this.area1 : this.area2
        }
      });
    }
  }
};